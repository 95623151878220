html,
body {
  height: 100%;
  width: 100%;
  /* background-color: #282c34; */
}


body {
  /* background-color: #000; */
  /* --bs-body-bg: #000; */
  background-color: #212529;
}

#root {
  height: 100%;
  width: 100%;
}

.dark {
  background-color: #212529;
}

.titlecolor {
  background-color: hsla(0, 0%, 100%, .1);
}

.titlebar {
  background-color: hsla(0, 0%, 100%, .1);
  /* rgba(0, 76, 153, .15) */
  padding-bottom: 0.4rem !important;
  padding-top: 0.4rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.imdisco {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 2em;
  background-image: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  display: inline-block;
  margin: 0;
  /* margin-left: 1%; */
  line-height: 1.2;
}

.tagline {
  /* font-family: Inter, sans-serif; */
  color: rgb(180, 180, 180);
  margin: 0;
  line-height: 1.2;
  font-weight: 400;
  /* margin-left: 1%; */
  /* margin-bottom: 15px; */
  font-size: .7em;
}

.imdisco-text {
  background: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.remix-button {
  background: #4e54c8;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  background: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  color: #fff;
  font-weight: bold;
  border: 2px solid #888;
  --bs-btn-bg: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  --bs-btn-hover-bg: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  outline: none;
  box-shadow: none;
}

.fleximg {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  /* max-height: "512px"; */
  object-fit: contain;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  /* user-select: none !important; */
}

.fab_previous {
  opacity: 0.25;
}

.fab_next {
  /* transparency */
  opacity: 0.5;
}

.fab_active {
  background: #4e54c8;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  background: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  color: #fff;
  font-weight: bold;
  border: 2px solid #888;
  --bs-btn-bg: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  --bs-btn-hover-bg: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  outline: none;
  box-shadow: none;
}

.remix_modal {
  outline: none;
  box-shadow: none;
}

.remix_modal:focus-visible {
  outline: none;
  box-shadow: none;
}

.fabric-canvas {
  height: 100%;
  width: 100%;
}

button.deleteBtn {
  background: none;
  border: none;
  color: #f44;
  font-size: 2em;
  opacity: 1;
  fill-opacity: 1;
}


.deleteBtn svg {
  color: #f44;
  fill-opacity: 1;
  opacity: 1;
  font-size: .8em;
}

button.fab_delete {
  /* background: none; */
  /* border: none; */
  color: #FFF;
  /* color: #f44; */
  /* font-size: 2em; */
  /* opacity: 1; */
  /* fill-opacity: 1; */
}